import {
  BooleanInput,
  NumberInput,
  Question,
  SelectInput,
} from 'app-components'
import {
  Button,
  Chip,
  Datepicker,
  Grid,
  Icon,
  LoadingAnimation,
  Text,
} from '@/components'
import { useContext, useEffect, useState } from 'react'
import Questionnaire, {
  QuestionnaireButtonType,
  QuestionnairePageValue,
} from '@/app/components/Questionnaire/Questionnaire'
import { useViewModel as questionnaireViewModel } from '@/app/components/Questionnaire/useViewModel'
import { BreakpointContext } from '@/context'
import dayjs from 'dayjs'
import { useWhiteLabelData } from '@/hooks/whitelabel/useWhiteLabelData'
import { useOnboardingSurveyResponses } from '@/app/features/onboarding/OnboardingQuestionnaire/utils'
import { HomeAddress } from '@/app/features/onboarding/OnboardingQuestionnaire/OnboardingQuestionnaire'

export interface Props {
  includeChargerQuestion: boolean
  onSubmit: (responses: QuestionnairePageValue[]) => void
  setShowSkipModalId: () => void
  showSkipModalId: string | null
  isLoading: boolean
  questionsToSkip: string[]
  isSubmitting: boolean
}

interface QuestionnairePageProps {
  onChange?: (event?: unknown) => void
}

function NumberOfEVs(props: QuestionnairePageProps) {
  const { getResponseByKey, onboardingSurvey } = useOnboardingSurveyResponses()
  const numberOfEVsResponse = getResponseByKey('numberOfEVs') as
    | number
    | undefined

  const [sentTeslaFlowRequest, setSentTeslaFlowRequest] =
    useState<boolean>(false)

  function onChange(value: number) {
    props.onChange?.(value)

    const counter = value
    if (counter > 0 && !sentTeslaFlowRequest) {
      setSentTeslaFlowRequest(true)
    }
  }

  return (
    <Question
      title={`How many electric or plug-in hybrid vehicles do you own?`}
    >
      <NumberInput
        range={[0, 99]}
        initialValue={numberOfEVsResponse}
        onChange={onChange}
      />
    </Question>
  )
}

function AwaitingEVDelivery(props: QuestionnairePageProps) {
  const breakpoint = useContext(BreakpointContext)
  const { getResponseByKey } = useOnboardingSurveyResponses()
  const thisMonth = dayjs().endOf('month').format('MM/DD/YYYY')
  const nextMonth = dayjs().add(1, 'month').endOf('month').format('MM/DD/YYYY')
  const awaitingEVDeliveryResponse = getResponseByKey('awaitingEVDelivery') as
    | { deliveryDate: string | null; awaitingDelivery: boolean }
    | undefined
  const [awaitingDelivery, setAwaitingDelivery] = useState<boolean | null>(
    awaitingEVDeliveryResponse?.awaitingDelivery ?? null,
  )
  const deliveryDate = awaitingEVDeliveryResponse?.deliveryDate ?? null
  const [datePickerDeliveryDate, setDatePickerDeliveryDate] = useState<
    string | null
  >(
    deliveryDate &&
      deliveryDate !== thisMonth &&
      deliveryDate !== nextMonth &&
      deliveryDate !== 'Who knows?'
      ? deliveryDate
      : null,
  )
  const expectedDeliveryOptions = [
    { label: 'This month', date: thisMonth },
    {
      label: 'Next month',
      date: nextMonth,
    },
    {
      value: (
        <Datepicker
          id="expected-delivery-datepicker"
          closeOnChange
          closeOnOutsideClick
          elevated
          initialValue={datePickerDeliveryDate}
          onChange={onDatepickerChange}
          resetOnOpen={datePickerDeliveryDate === null}
          style={{
            transformOrigin: breakpoint.mdAndUp ? 'top right' : 'top left',
            right: breakpoint.mdAndUp ? 0 : -177,
          }}
        >
          <Datepicker.Activator>
            <Chip
              data-testing-id="datepicker-chip-toggle"
              onClick={() => props.onChange?.([true, datePickerDeliveryDate])}
              style={{ fontVariantNumeric: 'tabular-nums' }}
            >
              {datePickerDeliveryDate ?? 'Select a date'}
            </Chip>
          </Datepicker.Activator>
        </Datepicker>
      ),
    },
    { label: 'Who knows at this point?', date: 'Who knows?' },
  ]

  function onAwaitingDeliveryChange(event: boolean | null) {
    setAwaitingDelivery(event)

    if (event) props.onChange?.([event, null])
    else props.onChange?.([event])
  }

  function onExpectedDeliverySelectChange(event: number | null) {
    if (event === null) {
      props.onChange?.([true, null])
      return
    }

    const selectOptionAssociatedDate = expectedDeliveryOptions[event]?.date
    if (selectOptionAssociatedDate === undefined) {
      return
    }
    props.onChange?.([true, selectOptionAssociatedDate])
  }

  function onDatepickerChange(date: string) {
    setDatePickerDeliveryDate(date)
    props.onChange?.([true, date])
  }

  return (
    <Grid flow="row" gap="32px" templateRows="auto">
      <Question
        title={`Are you awaiting delivery of an electric or plug-in hybrid vehicle?`}
      >
        <BooleanInput
          initialValue={awaitingDelivery}
          onChange={onAwaitingDeliveryChange}
        />
      </Question>
      {awaitingDelivery ? (
        <Question
          data-testing-id="expected-delivery"
          title="When are you expecting it?"
        >
          <SelectInput
            onChange={onExpectedDeliverySelectChange}
            options={expectedDeliveryOptions.map(
              (option) => option.label ?? option,
            )}
            initialValue={expectedDeliveryOptions.findIndex(
              (option) =>
                option.date === awaitingEVDeliveryResponse?.deliveryDate ||
                option.value?.props.initialValue ===
                  awaitingEVDeliveryResponse?.deliveryDate,
            )}
            toggleable={false}
          />
        </Question>
      ) : (
        <></>
      )}
    </Grid>
  )
}

function OwnsSmartCharger(props: QuestionnairePageProps) {
  const { getResponseByKey } = useOnboardingSurveyResponses()
  const ownsSmartChargerResponse = getResponseByKey('ownsSmartCharger') as
    | boolean
    | undefined
  const [ownsSmartCharger, setOwnsASmartCharger] = useState<
    boolean | undefined
  >(ownsSmartChargerResponse)

  function onChange(event: boolean | null) {
    props.onChange?.(event)
    setOwnsASmartCharger(event ?? false)
  }

  return (
    <Question title="Do you own a smart charger?">
      <Text variant="body2">
        Your vehicle's charger is 'smart' if it is connected to the internet and
        accessible via the manufacturer's app
      </Text>
      <BooleanInput initialValue={ownsSmartCharger} onChange={onChange} />

      {ownsSmartCharger === undefined ? (
        <></>
      ) : (
        <>
          {!ownsSmartCharger ? (
            <div>
              <Text variant="body2">
                No problem! You can always add one later.
              </Text>
              <Text variant="body2" style={{ paddingTop: 16 }}>
                Smart chargers are not required for EV managed charging program
                participation.
              </Text>
            </div>
          ) : (
            <div className="flex flex-col gap-4">
              <Text variant="body2">
                Great! We look forward to providing you with control and
                insights for your charger.
              </Text>
            </div>
          )}
        </>
      )}
    </Question>
  )
}

export type OnboardingQuestionnairePageKey =
  | 'numberOfEVs'
  | 'awaitingEVDelivery'
  | 'ownsSmartCharger'
  | 'homeAddress'

export type Page = {
  buttons: QuestionnaireButtonType[]
  order: number
  redirect?: string
  showButtons?: (values: QuestionnairePageValue[]) => QuestionnaireButtonType[]
  title?: string
  component: (props: QuestionnairePageProps) => JSX.Element
  skipModal?: (onSkipClick: () => void, onSkipClose: () => void) => JSX.Element
  shouldShow?: (values: QuestionnairePageValue[]) => boolean
  key: OnboardingQuestionnairePageKey
}

const pages = ({ ...props }: Props) =>
  [
    {
      buttons: ['next'],
      component: HomeAddress,
      order: 0,
      key: 'homeAddress',
    },
    {
      buttons: ['next'],
      component: NumberOfEVs,
      order: 1,
      key: 'numberOfEVs',
    },
    {
      buttons: ['next'],
      component: AwaitingEVDelivery,
      order: 2,
      key: 'awaitingEVDelivery',
    },
    {
      buttons: ['next'],
      component: OwnsSmartCharger,
      order: 3,
      key: 'ownsSmartCharger',
    },
  ] as Page[]

export default function AvaOnboardingQuestionnaire(props: Props) {
  const questionnaireViewModelProps = {
    className: 'onboarding-questionnaire',
    id: 'onboarding-questionnaire',
    pages: pages(props).filter(
      (page) => !props.questionsToSkip.includes(page.key),
    ),
    ...props,
  }
  const viewModel = questionnaireViewModel(questionnaireViewModelProps)
  const { whiteLabelData } = useWhiteLabelData()

  return (
    <>
      <div className="flex items-center justify-between w-full h-16">
        {viewModel.showBackButton && (
          <Button
            id={`ava-onboarding-questionnaire-back-button-page-${viewModel.pageNumber}`}
            variant="icon"
            onClick={viewModel.onBackClick}
          >
            <div className="flex items-center justify-center">
              <Icon name="ArrowLeft" size={24} color="grey-900" />
            </div>
          </Button>
        )}
        <div className="absolute left-1/2 transform -translate-x-1/2">
          {whiteLabelData?.brandLogo && (
            <div className="flex justify-center">
              <img
                src={whiteLabelData.brandLogo}
                className="max-w-[70px] max-h-[70px]"
              />
            </div>
          )}
        </div>
      </div>
      {props.isLoading ? (
        <div className="flex justify-center w-full">
          <LoadingAnimation type="falling" />
        </div>
      ) : (
        <Questionnaire {...viewModel} />
      )}
    </>
  )
}
