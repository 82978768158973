import useViewModel from 'features/onboarding/OnboardingQuestionnaire/useViewModel'
import AvaOnboardingQuestionnaire from './AvaOnboardingQuestionnaire'
import { ViewModelComponentProps } from '@/utils/typeUtilities'
import { Props as ComponentProps } from 'features/onboarding/OnboardingQuestionnaire'
import { ConfigurableFunctionalityProvider } from '@/app/context/ConfigurableFunctionalityContext'
import { FullPageCard } from 'app-components'

export type Props = ViewModelComponentProps<ComponentProps, typeof useViewModel>

export default function AvaOnboarding(props: Props) {
  const viewModel = useViewModel(props)

  return (
    <FullPageCard>
      <ConfigurableFunctionalityProvider scope="onboarding">
        <AvaOnboardingQuestionnaire {...viewModel} />
      </ConfigurableFunctionalityProvider>
    </FullPageCard>
  )
}
